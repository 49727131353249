import React from 'react';
import {Link} from 'react-router-dom';  // Importiere Link für die Navigation

const NutriPlan: React.FC = () => {
    return (
        <div className="container mt-4">
            <h1>NutriPlan</h1>
            <p>Willkommen beim NutriPlan! Entdecken Sie maßgeschneiderte Ernährungspläne, die auf wissenschaftlichen
                Empfehlungen basieren.</p>
            <div>
                <h2>Was ist NutriPlan?</h2>
                <p>NutriPlan bietet personalisierte Ernährungspläne, die darauf ausgelegt sind, Ihre Gesundheit und Ihr
                    Wohlbefinden zu unterstützen.
                    Unsere Pläne berücksichtigen moderne Ernährungswissenschaften und die Empfehlungen von
                    Ernährungsexperten.</p>
            </div>
            <div>
                <h2>Wie funktioniert es?</h2>
                <p>Starten Sie einfach, indem Sie uns etwas über Ihre Ernährungsziele und Vorlieben erzählen. Sie können
                    sofort mit einem Grundplan beginnen oder
                    sich anmelden, um einen vollständig angepassten Plan zu erhalten.</p>
            </div>
            <div className="alert alert-info" role="alert">
                <h4 className="alert-heading">Vollständige Funktionalität freischalten!</h4>
                <p>Für den Zugriff auf alle personalisierten Funktionen des NutriPlan benötigen Sie ein Benutzerkonto.
                    Registrieren Sie sich jetzt, um Ihren individuellen Ernährungsplan zu erstellen und alle Vorteile zu
                    nutzen.</p>
                <hr/>
                <p className="mb-0">
                    <Link to="/signup" className="btn btn-primary">Jetzt registrieren</Link>
                    <span className="ml-2"> oder </span>
                    <Link to="/login" className="btn btn-secondary ml-2">Anmelden</Link>
                </p>
            </div>
            <button className="btn btn-primary">Starten Sie Ihren Plan</button>
        </div>
    );
}

export default NutriPlan;
