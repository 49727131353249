import {Macros} from "../../api";
import React from "react";

const RecipeCardMacros: React.FC<{ macros: Macros }> = ({ macros }) => {
    return (
    <div className="nutrition-facts">
        <span className="nutrition-fact">Kalorien: {macros.calories}kcal</span>
        <span className="nutrition-fact">KH: {macros.carbohydrates}</span>
        <span className="nutrition-fact">Protein: {macros.protein}</span>
        <span className="nutrition-fact">Fett: {macros.fat}</span>
    </div>
    );
};

export default RecipeCardMacros;