import React, {createContext, useContext, useState, ReactNode, useCallback} from 'react';
import {login as apiLogin} from '../api';

interface AuthContextType {
    isAuthenticated: boolean;
    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    const login = async (username: string, password: string) => {
        try {
            const loginSuccess = await apiLogin(username, password); // Nutze die angepasste login Funktion
            if (loginSuccess) {
                setIsAuthenticated(true);  // Setze isAuthenticated auf true, wenn Login erfolgreich
                console.log('Login erfolgreich');
            } else {
                setIsAuthenticated(false);  // Optional: Setze isAuthenticated auf false, wenn Login fehlgeschlagen
                console.log('Login fehlgeschlagen');
            }
        } catch (error) {
            console.error('Login failed:', error);
            setIsAuthenticated(false);  // Sicherstellen, dass isAuthenticated auf false gesetzt ist bei einem Fehler
        }
    };

    const logout = () => {
        setIsAuthenticated(false); // Setze isAuthenticated auf false
    };

    return (
        <AuthContext.Provider value={{isAuthenticated, login, logout}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
