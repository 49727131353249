// SortDropdown.tsx

import React from 'react';
import './SortDropdown.css';

type SortKey =
    'caloriesAsc'
    | 'caloriesDesc'
    | 'proteinAsc'
    | 'proteinDesc'
    | 'fatAsc'
    | 'fatDesc'
    | 'carbohydratesAsc'
    | 'carbohydratesDesc'
    | 'totalTimeAsc'
    | 'totalTimeDesc';

interface SortDropdownProps {
    onSortChange: (key: SortKey) => void;
    className?: string;  // Optionaler className Prop
}

const SortDropdown: React.FC<SortDropdownProps> = ({onSortChange, className}) => {
    return (
        <select className={`sort-dropdown ${className || ''}`}
                onChange={(e) => onSortChange(e.target.value as SortKey)}>
            <option value="caloriesAsc">Kalorien ↑</option>
            <option value="caloriesDesc">Kalorien ↓</option>
            <option value="proteinAsc">Protein ↑</option>
            <option value="proteinDesc">Protein ↓</option>
            <option value="fatAsc">Fett ↑</option>
            <option value="fatDesc">Fett ↓</option>
            <option value="carbsAsc">Kohlenhydrate ↑</option>
            <option value="carbsDesc">Kohlenhydrate ↓</option>
            <option value="totalTimeAsc">Gesamtzeit ↑</option>
            <option value="totalTimeDesc">Gesamtzeit ↓</option>
        </select>
    );
};

export default SortDropdown;
