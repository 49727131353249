// components/Home.tsx
import React from 'react';

const Home: React.FC = () => {
    return (
        <div className="container mt-3">
            <h1>Willkommen zu Nutricipe</h1>
            <p>Diese Website hat eine Datenbank an Rezepten, die es ermöglicht eine Ernährungsplan mit allen wichtigen
                Nährstoffen für jede Ernährungsform bereit zu stellen. Viel Spaß!</p>
        </div>
    );
};

export default Home;
