// components/RecipeCard.tsx
import React from 'react';
import {useNavigate} from 'react-router-dom';
import RecipeCardTitle from "./RecipeCardTitle";
import RecipeCardDescription from "./RecipeCardDescription";
import RecipeCardMacros from "./RecipeCardMacros";
import RecipeCardTime from "./RecipeCardTime";

interface RecipeCardProps {
    id: string;
    title: string;
    description: string;
    nutrition: {
        macros: {
            calories: number;
            carbohydrates: number;
            protein: number;
            fat: number;
        };
    };
    totalTime: string;
}

const RecipeCard: React.FC<RecipeCardProps> = ({id, title, description, nutrition, totalTime}) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/recipes/${id}`);
    };

    return (
        <div className="card h-100" onClick={handleClick}>
            <div className="card-body">
                <RecipeCardTitle title={title}/>
                <RecipeCardDescription description={description}/>
                <RecipeCardMacros macros={nutrition.macros}/>
                <RecipeCardTime time={totalTime}/>
            </div>
        </div>
    );
};

export default RecipeCard;
