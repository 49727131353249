import React, {useState, useEffect} from 'react';
import Select, {MultiValue, ActionMeta} from 'react-select';
import {fetchIngredientOptions, fetchLimits, FilterLimits, Filters} from '../api';
import './AdvancedSearchPanel.css';

interface AdvancedSearchPanelProps {
    applyFilters: (filters: Filters) => void;
}

interface OptionType {
    value: string;
    label: string;
}

const AdvancedSearchPanel: React.FC<AdvancedSearchPanelProps> = ({applyFilters}) => {
    const [localFilters, setLocalFilters] = useState<Filters>({
        minCalories: 0,
        maxCalories: 2000,
        minProtein: 0,
        maxProtein: 200,
        minFat: 0,
        maxFat: 100,
        minCarbohydrates: 0,
        maxCarbohydrates: 300,
        includedIngredients: [],
        excludedIngredients: []
    });
    const [filterLimits, setFilterLimits] = useState<FilterLimits | null>(null);
    const [ingredientOptions, setIngredientOptions] = useState<OptionType[]>([]);
    const [initialLoad, setInitialLoad] = useState(true); // New state to track initial load


    useEffect(() => {
        const getLimitsAndIngredients = async () => {
            try {
                const [limits, ingredients] = await Promise.all([
                    fetchLimits(),
                    fetchIngredientOptions()
                ]);
                setFilterLimits(limits);
                setIngredientOptions(ingredients.map((ingredient: {
                    id: string;
                    name: string;
                }) => ({value: ingredient.id, label: ingredient.name})));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        getLimitsAndIngredients();
    }, []);

    const handleSelectIngredients = (selected: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
        const action = actionMeta.action;
        const name = actionMeta.name;
        const selectedIds = selected.map(option => option.value);

        if (name === 'includedIngredients') {
            setLocalFilters(prev => ({
                ...prev,
                includedIngredients: selectedIds,
                excludedIngredients: prev.excludedIngredients.filter(id => !selectedIds.includes(id))
            }));
        } else if (name === 'excludedIngredients') {
            setLocalFilters(prev => ({
                ...prev,
                excludedIngredients: selectedIds,
                includedIngredients: prev.includedIngredients.filter(id => !selectedIds.includes(id))
            }));
        }
    };


    useEffect(() => {
        if (filterLimits && initialLoad) {
            setLocalFilters({
                minCalories: filterLimits.minCalories,
                maxCalories: filterLimits.maxCalories,
                minProtein: filterLimits.minProtein,
                maxProtein: filterLimits.maxProtein,
                minFat: filterLimits.minFat,
                maxFat: filterLimits.maxFat,
                minCarbohydrates: filterLimits.minCarbohydrates,
                maxCarbohydrates: filterLimits.maxCarbohydrates,
                includedIngredients: [],
                excludedIngredients: []
            });
            setInitialLoad(false);
        }
    }, [filterLimits]);

    const handleChange = (name: keyof Filters, value: number) => {
        setLocalFilters((prev: Filters) => ({...prev, [name]: value}));
    };

    const handleApplyFilters = () => {
        applyFilters(localFilters);
        console.log('Applied filters:', localFilters);
    };

    return (
        <div className="advanced-search-panel">
            {filterLimits ? (
                <>
                    <div className="filter-group">
                        <label>Kalorien:</label>
                        <div className="range-inputs">
                            <input
                                type="number"
                                min={filterLimits.minCalories}
                                max={filterLimits.maxCalories}
                                value={localFilters.minCalories}
                                onChange={e => handleChange('minCalories', parseInt(e.target.value))}
                            />
                            <input
                                type="number"
                                min={filterLimits.minCalories}
                                max={filterLimits.maxCalories}
                                value={localFilters.maxCalories}
                                onChange={e => handleChange('maxCalories', parseInt(e.target.value))}
                            />
                        </div>
                    </div>
                    <div className="filter-group">
                        <label>Protein (g):</label>
                        <div className="range-inputs">
                            <input
                                type="number"
                                min={filterLimits.minProtein}
                                max={filterLimits.maxProtein}
                                value={localFilters.minProtein}
                                onChange={e => handleChange('minProtein', parseInt(e.target.value))}
                            />
                            <input
                                type="number"
                                min={filterLimits.minProtein}
                                max={filterLimits.maxProtein}
                                value={localFilters.maxProtein}
                                onChange={e => handleChange('maxProtein', parseInt(e.target.value))}
                            />
                        </div>
                    </div>
                    <div className="filter-group">
                        <label>Fett (g):</label>
                        <div className="range-inputs">
                            <input
                                type="number"
                                min={filterLimits.minFat}
                                max={filterLimits.maxFat}
                                value={localFilters.minFat}
                                onChange={e => handleChange('minFat', parseInt(e.target.value))}
                            />
                            <input
                                type="number"
                                min={filterLimits.minFat}
                                max={filterLimits.maxFat}
                                value={localFilters.maxFat}
                                onChange={e => handleChange('maxFat', parseInt(e.target.value))}
                            />
                        </div>
                    </div>
                    <div className="filter-group">
                        <label>Kohlenhydrate (g):</label>
                        <div className="range-inputs">
                            <input
                                type="number"
                                min={filterLimits.minCarbohydrates}
                                max={filterLimits.maxCarbohydrates}
                                value={localFilters.minCarbohydrates}
                                onChange={e => handleChange('minCarbohydrates', parseInt(e.target.value))}
                            />
                            <input
                                type="number"
                                min={filterLimits.minCarbohydrates}
                                max={filterLimits.maxCarbohydrates}
                                value={localFilters.maxCarbohydrates}
                                onChange={e => handleChange('maxCarbohydrates', parseInt(e.target.value))}
                            />
                        </div>
                    </div>
                    <div className="filter-group">
                        <label>Inkludierte Zutaten:</label>
                        <Select
                            isMulti
                            name="includedIngredients"
                            options={ingredientOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(selected, actionMeta) => handleSelectIngredients(selected, actionMeta)}
                            value={ingredientOptions.filter(option => localFilters.includedIngredients.includes(option.value))}
                        />
                    </div>
                    <div className="filter-group">
                        <label>Exkludierte Zutaten:</label>
                        <Select
                            isMulti
                            name="excludedIngredients"
                            options={ingredientOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(selected, actionMeta) => handleSelectIngredients(selected, actionMeta)}
                            value={ingredientOptions.filter(option => localFilters.excludedIngredients.includes(option.value))}
                        />
                    </div>
                    <div className="button-container">
                        <button className="btn btn-primary mt-3" onClick={handleApplyFilters}>Filter anwenden</button>
                    </div>
                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}
export default AdvancedSearchPanel;
