// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.advanced-search-panel {
    padding: 10px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.filter-group {
    margin-bottom: 15px;
}

.filter-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.range-inputs {
    display: flex;
    gap: 10px;
}

.range-inputs input[type="number"] {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.btn {
    padding: 10px 20px;
    font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdvancedSearchPanel.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".advanced-search-panel {\n    padding: 10px;\n    background: #fff;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n}\n\n.filter-group {\n    margin-bottom: 15px;\n}\n\n.filter-group label {\n    display: block;\n    font-weight: bold;\n    margin-bottom: 5px;\n}\n\n.range-inputs {\n    display: flex;\n    gap: 10px;\n}\n\n.range-inputs input[type=\"number\"] {\n    width: 100%;\n    padding: 5px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}\n\n.button-container {\n    display: flex;\n    justify-content: center;\n    margin-top: 15px;\n}\n\n.btn {\n    padding: 10px 20px;\n    font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
