// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form-container {
    height: 100vh; /* Nutze die volle Höhe des Viewports */
    background-color: #f8f9fa; /* Ein leichter Hintergrund */
}

.login-form {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, .1);
    border-radius: 5px;
}

.form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}

.btn-block {
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/LoginForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa,EAAE,uCAAuC;IACtD,yBAAyB,EAAE,6BAA6B;AAC5D;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,uCAAuC;IACvC,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".login-form-container {\n    height: 100vh; /* Nutze die volle Höhe des Viewports */\n    background-color: #f8f9fa; /* Ein leichter Hintergrund */\n}\n\n.login-form {\n    width: 100%;\n    max-width: 330px;\n    padding: 15px;\n    background-color: white;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, .1);\n    border-radius: 5px;\n}\n\n.form-control {\n    position: relative;\n    box-sizing: border-box;\n    height: auto;\n    padding: 10px;\n    font-size: 16px;\n}\n\n.btn-block {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
