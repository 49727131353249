import React from 'react';
import {useAuth} from '../context/AuthContext';  // Pfad überprüfen

const Profile: React.FC = () => {
    const {isAuthenticated, logout} = useAuth();

    if (!isAuthenticated) {
        // Zeige eine Nachricht an oder leite auf die Login-Seite um
        return (
            <div>
                <h1>Nicht angemeldet</h1>
                <p>Sie müssen angemeldet sein, um auf das Profil zugreifen zu können.</p>
                {/* Optional: Link oder Button für die Umleitung zur Anmeldeseite */}
            </div>
        );
    }

    return (
        <div className="container mt-3">
            <h1>Profil</h1>
            <div>
                <p>Willkommen in Ihrem Profilbereich. Hier könnten Benutzerinformationen oder spezifische Funktionen
                    angezeigt werden.</p>
                <button onClick={logout} className="btn btn-danger">Logout</button>
            </div>
        </div>
    );
};

export default Profile;
