// api.ts
import axios from 'axios';

// Definiere die Schnittstellen für deine Daten
export interface Instruction {
    step: string;
    duration: string;
    tool: string;
}

export interface Macros {
    calories: number;
    carbohydrates: number;
    protein: number;
    fat: number;
}

export interface Micronutrient {
    name: string;
    amount: string;
}

export interface Nutrition {
    macros: Macros;
    micros: Micronutrient[];
}

export interface RecipeIngredient {
    ingredientId: string;
    name: string;
    quantity: number;
    unit: string;
}

export interface Recipe {
    id: string;
    title: string;
    description: string;
    ingredients: RecipeIngredient[];
    instructions: Instruction[];
    totalTime: string;
    nutrition: Nutrition;
}

export interface Filters {
    minCalories: number;
    maxCalories: number;
    minProtein: number;
    maxProtein: number;
    minFat: number;
    maxFat: number;
    minCarbohydrates: number;
    maxCarbohydrates: number;
    includedIngredients: string[];
    excludedIngredients: string[];
}


export interface FilterLimits {
    minCalories: number;
    maxCalories: number;
    minProtein: number;
    maxProtein: number;
    minFat: number;
    maxFat: number;
    minCarbohydrates: number;
    maxCarbohydrates: number;
}

// Setze die Basis-URL für alle API-Anfragen
const API_URL = process.env.REACT_APP_API_URL || 'https://nutricipe-backend-90d773a10d59.herokuapp.com';
//const API_URL = 'http://localhost:8080';
// Hole alle Rezepte
export const fetchRecipes = async (): Promise<Recipe[]> => {
    try {
        const response = await axios.get<Recipe[]>(`${API_URL}/recipes`);
        return response.data || [];  // Fallback zu einem leeren Array
    } catch (error) {
        console.error("Error fetching recipes", error);
        return [];  // Rückgabe eines leeren Arrays bei einem Fehler
    }
};

export const fetchIngredientOptions = async () => {
    try {
        const response = await axios.get(`${API_URL}/recipes/ingredients`);
        return response.data;
    } catch (error) {
        console.error('Error fetching ingredient options:', error);
        throw error;  // Weitergeben des Fehlers an die aufrufende Komponente
    }
};

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const login = async (username: string, password: string): Promise<boolean> => {
    try {
        const response = await axios.post(`${API_URL}/api/auth/signin`, {username, password});
        console.log('Login erfolgreich:', response.data);
        localStorage.setItem('token', response.data.token); // Speichere das Token
        return true; // Rückgabe, dass Login erfolgreich war
    } catch (error) {
        console.error('Login fehlgeschlagen:', error);
        return false; // Rückgabe, dass Login fehlgeschlagen ist
    }
};

// Hole ein Rezept nach ID
export const fetchRecipeById = async (id: string): Promise<Recipe> => {
    try {
        console.log("Fetching recipe with ID:", id);
        const response = await axios.get<Recipe>(`${API_URL}/recipes/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching recipe", error);
        throw error;  // Weitergeben des Fehlers an die aufrufende Komponente
    }
};

export const searchRecipes = async (query: string): Promise<Recipe[]> => {
    const response = await axios.get<Recipe[]>(`${API_URL}/recipes/search`, {
        params: {query}
    });
    return response.data;
};

export const advancedSearchRecipes = async (query: string, filters: Filters): Promise<Recipe[]> => {
    console.log('Advanced search filters in api:', filters);
    const response = await axios.get<Recipe[]>(`${API_URL}/recipes/advanced-search`, {
        params: {
            query,
            minCalories: filters.minCalories,
            maxCalories: filters.maxCalories,
            minProtein: filters.minProtein,
            maxProtein: filters.maxProtein,
            minFat: filters.minFat,
            maxFat: filters.maxFat,
            minCarbohydrates: filters.minCarbohydrates,
            maxCarbohydrates: filters.maxCarbohydrates,
            includedIngredients: filters.includedIngredients,
            excludedIngredients: filters.excludedIngredients
        },
        paramsSerializer: (params) => {
            return Object.keys(params)
                .map(key => {
                    if (Array.isArray(params[key])) {
                        return params[key]
                            .map((val: any) => `${key}=${encodeURIComponent(val)}`)
                            .join('&');
                    }
                    return `${key}=${encodeURIComponent(params[key])}`;
                })
                .join('&');
        }
    });
    return response.data;
};

export const fetchLimits = async (): Promise<FilterLimits> => {
    try {
        const response = await axios.get<FilterLimits>(`${API_URL}/recipes/filter-limits`);
        return response.data;
    } catch (error) {
        console.error('Error fetching limits:', error);
        throw error;
    }
};

