import React, {useEffect, useState} from 'react';
import './RecipeDetail.css';
import {useNavigate, useParams} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle as fasFaCircle} from '@fortawesome/free-solid-svg-icons';
import {fetchRecipeById, Recipe, RecipeIngredient} from '../api';

const RecipeDetail: React.FC = () => {
    const [recipe, setRecipe] = useState<Recipe | null>(null);
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        const loadRecipe = async () => {
            try {
                if (id) {
                    const fetchedRecipe = await fetchRecipeById(id);
                    console.log('Gefetchtes Rezept:', fetchedRecipe); // Überprüfe die API-Antwort
                    console.log('Schritte:', fetchedRecipe.instructions); // Überprüfe die Anweisungen (instructions

                    if (fetchedRecipe.instructions && fetchedRecipe.instructions.length > 0 && typeof fetchedRecipe.instructions[0] === 'string') {
                        // @ts-ignore
                        fetchedRecipe.instructions = fetchedRecipe.instructions.map(inst => JSON.parse(inst));
                    }

                    // Überprüfen nach der Konvertierung
                    const areInstructionsCorrect = fetchedRecipe.instructions.every(inst =>
                        inst.hasOwnProperty('step') && inst.hasOwnProperty('duration') && inst.hasOwnProperty('tool'));

                    console.log('Are instructions correctly formatted after conversion?', areInstructionsCorrect);

                    // Setzen Sie den State nur, wenn die Anweisungen korrekt sind
                    if (areInstructionsCorrect) {
                        setRecipe(fetchedRecipe);
                    } else {
                        // Fallback, falls die Daten nicht korrekt sind
                        console.error('Instructions are missing or malformed after attempted conversion', fetchedRecipe.instructions);
                    }
                }
            } catch (error) {
                console.error('Error loading recipe:', error);
            }
        };

        loadRecipe();
    }, [id]);


    function handleBack() {
        navigate('/recipes');
    }

    if (!recipe) {
        return <div>Laden...</div>;
    }

    return (
        <div className="recipe-detail-container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <h2 className="recipe-title">{recipe.title}</h2>
                    <p className="text-center mb-4">{recipe.description}</p>
                    <h3>Zutaten</h3>
                    <ul className="list-unstyled">
                        {recipe.ingredients.map((ingredient: RecipeIngredient, index) => (
                            <li key={index}>
                                <FontAwesomeIcon icon={fasFaCircle} className="small-bullet"/>
                                {`${ingredient.name}: ${ingredient.quantity} ${ingredient.unit}`}
                            </li>
                        ))}
                    </ul>
                    <div className="instructions-container">
                        <h3>Anweisungen</h3>
                        <ol className="list-group list-group-numbered">
                            {recipe.instructions.map((instruction, index) => (
                                <li key={index} className="list-group-item">
                                    {instruction.step} (Dauer: {instruction.duration}, Werkzeug: {instruction.tool})
                                </li>
                            ))}
                        </ol>
                    </div>
                </div>
            </div>
            <button onClick={handleBack} className="btn btn-success">Zurück zur Übersicht</button>
        </div>
    );
};

export default RecipeDetail;
