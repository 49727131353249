import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {AuthProvider} from './context/AuthContext';  // Pfad anpassen
import Navbar from './components/Navbar';
import Home from './components/Home';
import RecipeGrid from './components/RecipeGrid';
import RecipeDetail from './components/RecipeDetail';
import LoginForm from "./components/LoginForm";
import Profile from './components/Profile';
import 'bootstrap/dist/css/bootstrap.min.css';
import NutriPlan from "./components/NutriPlan";

function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Navbar/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/recipes" element={<RecipeGrid/>}/>
                    <Route path="/recipes/:id" element={<RecipeDetail/>}/>
                    <Route path="/nutriplan" element={<NutriPlan/>}/>
                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/login" element={<LoginForm/>}/> {/* Ensure you have a LoginForm component */}
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;
