import React, {useState, FormEvent} from 'react';
import {useAuth} from '../context/AuthContext';  // Überprüfe den Pfad
import './LoginForm.css';
import {useNavigate} from "react-router-dom";  // Stelle sicher, dass du die CSS-Datei korrekt einbindest

const LoginForm: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const {login} = useAuth();
    const navigate = useNavigate();  // Initialisiere useNavigate

    const handleLogin = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        try {
            await login(username, password);
            console.log('Logged in');
            navigate('/');  // Navigiere zur Startseite nach erfolgreichem Login
        } catch (err) {
            setError('Login failed. Please check your credentials.');
            console.error(err);
        }
    };

    return (
        <div className="login-form-container d-flex align-items-center justify-content-center">
            <form onSubmit={handleLogin} className="login-form text-center">
                <h3 className="mb-3">Login</h3>
                <div className="form-group mb-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Username"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group mb-3">
                    <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary btn-block mb-3">Login</button>
                {error && <div className="alert alert-danger">{error}</div>}
            </form>
        </div>
    );
};

export default LoginForm;
