import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useAuth} from '../context/AuthContext';  // Pfad anpassen

const Navbar: React.FC = () => {
    const navigate = useNavigate();
    const {isAuthenticated, logout} = useAuth();

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">Nutricipe</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link" to="/">Startseite</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/recipes">Rezepte</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/nutriplan">NutriPlan</Link> {/* Neuer Link */}
                        </li>
                    </ul>
                    <ul className="navbar-nav">
                        {isAuthenticated ? (
                            <>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/profile">Profil</Link>
                                </li>
                                <li className="nav-item">
                                    <button className="btn btn-outline-danger" onClick={() => {
                                        logout();
                                        navigate('/');
                                    }}>Logout
                                    </button>
                                </li>
                            </>
                        ) : (
                            <li className="nav-item">
                                <button className="btn btn-outline-success" onClick={() => navigate('/login')}>Login
                                </button>
                                <button className="btn btn-outline-success" onClick={() => navigate('/signup')}>Sign
                                    Up
                                </button>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
