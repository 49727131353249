import React, {useEffect, useState} from 'react';
import RecipeCard from './RecipeCard/RecipeCard';
import {Recipe, fetchRecipes, searchRecipes, Filters, advancedSearchRecipes} from '../api';
import AdvancedSearchPanel from './AdvancedSearchPanel';
import './RecipeGrid.css';
import SortDropdown from "./SortDropdown";

const RecipeGrid: React.FC = () => {
    // Zustand für die Liste der Rezepte
    const [recipes, setRecipes] = useState<Recipe[]>([]);
    // Zustand für den Suchbegriff
    const [searchTerm, setSearchTerm] = useState('');
    // Zustand für die Filter
    const [filters, setFilters] = useState<Filters | null>(null);
    // Zustand für das Öffnen/Schließen des Filter-Panels
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [sortKey, setSortKey] = useState('');


    // useEffect Hook, um Rezepte zu laden, wenn sich searchTerm oder filters ändern
    useEffect(() => {
        const getRecipes = async () => {
            // Normale Suche ohne Filter
            let fetchedRecipes = searchTerm ? await searchRecipes(searchTerm) : await fetchRecipes();
            setRecipes(fetchedRecipes);
        };
        getRecipes();
    }, [searchTerm]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Funktion zum Anwenden der Filter
    const applyFilters = async (newFilters: Filters) => {
        setFilters(newFilters);
        setIsFiltersOpen(false); // Schließe das Filterpanel nach dem Anwenden der Filter
        console.log('New filters:', newFilters);

        // Erweiterte Suche mit Filtern
        const fetchedRecipes = await advancedSearchRecipes(searchTerm, newFilters);
        setRecipes(fetchedRecipes);
    };

    const handleToggleFilters = () => {
        setIsFiltersOpen(!isFiltersOpen);
    };

    type SortKey =
        'caloriesAsc'
        | 'caloriesDesc'
        | 'proteinAsc'
        | 'proteinDesc'
        | 'fatAsc'
        | 'fatDesc'
        | 'carbohydratesAsc'
        | 'carbohydratesDesc'
        | 'totalTimeAsc'
        | 'totalTimeDesc';

    const handleSortChange = (key: SortKey) => {
        const [field, order] = key.split(/(Asc|Desc)$/); // Teilt den Schlüssel in Feld und Reihenfolge
        const isAscending = order === 'Asc';

        const sortedRecipes = [...recipes].sort((a, b) => {
            const valueA = a.nutrition.macros[field as keyof typeof a.nutrition.macros];
            const valueB = b.nutrition.macros[field as keyof typeof b.nutrition.macros];
            if (valueA === valueB) return 0;
            return isAscending ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA);
        });

        setRecipes(sortedRecipes);
    };


    return (
        <div className="container-fluid mt-4">
            <div className={`search-bar ${isFiltersOpen ? (isMobile ? 'with-topbar' : 'with-sidebar') : ''}`}>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Rezepte suchen..."
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                    style={{flex: 1}}
                />
                <button className="btn btn-outline-success ms-2" onClick={handleToggleFilters}>
                    Erweiterte Suche
                </button>
            </div>
            <div className={`${isMobile ? 'topbar' : 'sidebar'} ${isFiltersOpen ? 'open' : ''}`}>
                <AdvancedSearchPanel applyFilters={applyFilters}/>
            </div>
            <div className={`main-content ${isFiltersOpen ? (isMobile ? 'with-topbar' : 'with-sidebar') : ''}`}>
                <SortDropdown className="btn btn-outline-secondary" onSortChange={handleSortChange}/>
            </div>
            <div className={`main-content ${isFiltersOpen ? (isMobile ? 'with-topbar' : 'with-sidebar') : ''}`}>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    {recipes.map(recipe => (
                        <div className="col" key={recipe.id}>
                            <RecipeCard
                                id={recipe.id}
                                title={recipe.title}
                                description={recipe.description}
                                nutrition={recipe.nutrition}
                                totalTime={recipe.totalTime}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RecipeGrid;
